import { Text, TextVariant } from '@naf/text';
import { spacing, themeLight } from '@naf/theme';
import React from 'react';
import styled from 'styled-components';
import { IframeBlockType } from '../../../../../types/CategoryAppType';

type IframeBlockProps = { block: IframeBlockType };

const IframeBlock = (props: IframeBlockProps) => {
  const {
    block: { src, height, title, ingress },
  } = props;

  return (
    <Container>
      {title && <TitleText variant={TextVariant.Header1}>{title}</TitleText>}
      {ingress && <IngressText variant={TextVariant.Ingress}>{ingress}</IngressText>}
      <FullwidthIframe title={src} src={src} height={height} />
    </Container>
  );
};

const TitleText = styled(Text)`
  margin: 0;
`;

const IngressText = styled(Text)`
  margin: ${spacing.space16} 0 ${spacing.space24} 0;
`;

const Container = styled.div`
  width: 100%;
`;

const FullwidthIframe = styled.iframe`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.border.subtle || themeLight.border.subtle};
  margin: -2px;
`;

export { IframeBlock, Container };
